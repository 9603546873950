import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/grader";

  // return "http://192.168.1.12:3003/general/grader"
};


export const loadStateDistrictList = (state, district, data) => {
  const url =
    state === undefined && district === undefined
      ? `${baseURL()}/state`
      : state !== undefined && district === undefined
      ? `${baseURL()}/state/${state}`
      : state !== undefined && district !== undefined
      ? `${baseURL()}/state/${state}/${district}`
      : null;

  return axios.post(url, data);
};


export const loadLanguages = data => {
  const url = `${baseURL()}/languages/list`;
  return axios.post(url, data);
};

export const loadExpressRecyclingList = data => {
  const url = `${baseURL()}/expressRecycling/load`;
  return axios.post(url, data);
};

export const loadRecyclingList = data => {
  const url = `${baseURL()}/recycling/load`;
  return axios.post(url, data);
};

export const loadRecyclingCategory = data => {
  const url = `${baseURL()}/recycling/category/load`;
  return axios.post(url, data);
};

export const loadRecyclingExtraFee = data => {
  const url = `${baseURL()}/recycling/extraFee/load`;
  return axios.post(url, data);
};

export const loadRecyclingDumpster = data => {
  const url = `${baseURL()}/recycling/dumpster/load`;
  return axios.post(url, data);
};

export const loadDumpsterService = data => {
  const url = `${baseURL()}/dumpsterService/load`;
  return axios.post(url, data);
};

//Carbon Footprint
export const loadImpactCarbon = data => {
  const url = `${baseURL()}/impact/load`;
  return axios.post(url, data);
};

export const getImpactCarbonCert = data => {
  const url = `${baseURL()}/impact/cert`;
  return axios.post(url, data);
};

//Settings
export const getGeneralSetting = data => {
  const url = `${baseURL()}/setting/load`;
  return axios.post(url, data);
};